(function($) {

    $(document).ready(function() {

        //In this site, the navbar is already fixed.
        //I just want to change the header's class once the window scrolled a few pixels

        var headerHeight = $('header').height();

         jQuery(window).resize(function() {
             headerHeight = $('header').height();
         });

         jQuery(window).scroll(function() {

            if (jQuery(this).scrollTop() > (headerHeight/2)) {
              //get width of container here
              $('header').addClass('banner-scrolled');

            } else {
              $('header').removeClass('banner-scrolled');
            }
         });

    });

})(jQuery, window);
