(function($) {

    $(document).ready(function () {

      // Off Canvas Navigation
      var offcanvas_open = false;
      var offcanvas_from_left = false;


      function offcanvas_right() {

        $(".myside-menu-container").addClass("slide-from-left");
        $(".myside-menu-container").addClass("myside-menu-open");

        offcanvas_open = true;
        offcanvas_from_left = true;

        $(".myside-menu").addClass("open");
        $("body").addClass("offcanvas_open offcanvas_from_left");

        //$(".nano").nanoScroller();

      }


      function offcanvas_close() {
        if (offcanvas_open === true) {

          $(".myside-menu-container").removeClass("slide-from-left");
          $(".myside-menu-container").removeClass("myside-menu-open");

          offcanvas_open = false;
          offcanvas_from_left = false;

          //$('#sidebar-menu-container').css('max-height', 'inherit');
          $(".myside-menu").removeClass("open");
          $("body").removeClass("offcanvas_open offcanvas_from_left");

        }
      }


      $(".menubtn").on('click', function(e) {
        e.stopPropagation();
        //event.stop();
        offcanvas_right();
      });


      $("#myside-menu-container").on("click", ".myside-menu-overlay", function(e) {
        offcanvas_close();
      });

      /*
      $(".myside-menu-overlay").swipe({
        swipeLeft:function(event, direction, distance, duration, fingerCount) {
          offcanvas_close();
        },
        swipeRight:function(event, direction, distance, duration, fingerCount) {
          offcanvas_close();
        },
        tap:function(event, direction, distance, duration, fingerCount) {
          offcanvas_close();
        },
        threshold:0
      });
      */


      // Sidebar/Mobile navigation
  		$(".responsive-menu .menu-item-has-children").append('<div class="show-submenu"><i class="fa fa-chevron-circle-down"></i></div>');

  	    $(".responsive-menu").on("click", ".show-submenu", function(e) {
  			     e.stopPropagation();

    			   $(this).parent().toggleClass("current").children(".sub-menu").toggleClass("open");

    			   $(this).html($(this).html() === '<i class="fa fa-chevron-circle-down"></i>' ? '<i class="fa fa-chevron-circle-up"></i>' : '<i class="fa fa-chevron-circle-down"></i>');
    			   //$(".nano").nanoScroller();
    		});

    		$(".responsive-menu").on("click", "a", function(e) {
    			if( ($(this).attr('href') === "#") || ($(this).attr('href') === "") ) {
    				$(this).parent().children(".show-submenu").trigger("click");
    				return false;
    			} else {
    				offcanvas_close();
    			}
    		});

    });

})(jQuery, window);
