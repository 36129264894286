(function($) {

    $(document).ready(function () {
        $("#hp-owl").owlCarousel({
            'items': 1,
            'singleItem': true,
            'stopOnHover': true,
            'autoPlay': 9000,
            'pagination': false,
            'autoHeight': true
        });



        /*
        function updateSize(){
            var minHeight=parseInt($('.owl-item').eq(0).css('height'));
            $('.owl-item').each(function () {
                var thisHeight = parseInt($(this).css('height'));
                minHeight=(minHeight<=thisHeight?minHeight:thisHeight);
            });
            $('.owl-wrapper-outer').css('height',minHeight+'px');
        }
        */

        var pImages = $('#pimages');
        var pThumbs = $('#pthumbs');

        function center(number){
              var thumbsvisible = pThumbs.data("owlCarousel").owl.visibleItems;
              var num = number;
              var found = false;
              for(var i in thumbsvisible){
                if(num === thumbsvisible[i]){
                  found = true;
                }
              }

              if(found===false){
                if(num>thumbsvisible[thumbsvisible.length-1]){
                  pThumbs.trigger("owl.goTo", num - thumbsvisible.length+2);
                }else{
                    if(num - 1 === -1){
                        num = 0;
                      }
                    pThumbs.trigger("owl.goTo", num);
                }
            } else if(num === thumbsvisible[thumbsvisible.length-1]){
                    pThumbs.trigger("owl.goTo", thumbsvisible[1]);
              } else if(num === thumbsvisible[0]){
                    pThumbs.trigger("owl.goTo", num-1);
              }

          }

          function syncPosition(el){
            var current = this.currentItem;
            $("#pthumbs")
              .find(".owl-item")
              .removeClass("synced")
              .eq(current)
              .addClass("synced");
            if($("#pthumbs").data("owlCarousel") !== undefined){
              center(current);
            }
          }

          $("#pthumbs").on("click", ".owl-item", function(e){
            e.preventDefault();
            var number = $(this).data("owlItem");
            pImages.trigger("owl.goTo",number);
          });



          pImages.owlCarousel({
              'singleItem': true,
              'pagination': false,
              'slideSpeed' : 1000,
              'lazyLoad' : true,
              //'autoHeight': true,
              'navigation': true,
              'navigationText': [
                "<i class='fa fa-chevron-left'></i>",
                "<i class='fa fa-chevron-right'></i>"
                ],
              'afterAction' : syncPosition,
              'responsiveRefreshRate' : 200,
              afterInit:function(){
                  //updateSize();
              }
          });

          pThumbs.owlCarousel({
              items : 8,
              itemsDesktop      : [1199,8],
              itemsDesktopSmall     : [979,8],
              itemsTablet       : [768,6],
              itemsMobile       : [479,4],
              pagination:false,
              responsiveRefreshRate : 100,
              afterInit : function(el){
                el.find(".owl-item").eq(0).addClass("synced");
              }
          });
    });

})(jQuery, window);
